import Login from '../components/Login';

export default function login() {
  return (
    <>
      <Login />
    </>
  );
}

export const getServerSideProps = async (context) => {
  try {
    const auth = context?.req?.cookies?.AUTH ? JSON.parse(context.req.cookies.AUTH) : undefined;

    if (auth) {
      return {
        redirect: {
          destination: '/'
        }
      };
    }
  } catch (error) {
    return {
      props: {}
    };
  }
  return {
    props: {}
  };
};
