import { useState, useEffect } from 'react';

const useCountdownTimer = () => {
  const [count, setCount] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isRunning && count > 0) {
      intervalId = setInterval(() => {
        setCount(prevCount => prevCount - 1);
      }, 1000);
    } else if (count === 0) {
      setIsRunning(false);
    }

    return () => clearInterval(intervalId);
  }, [isRunning, count]);

  const startTimer = (initialCount: number) => {
    setCount(initialCount);
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setCount(0);
    setIsRunning(false);
  };

  return {
    count,
    isRunning,
    startTimer,
    stopTimer,
    resetTimer,
  };
};

export default useCountdownTimer;
