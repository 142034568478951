import { useState, useEffect } from 'react';

const useClientInfo = () => {
  const [clientInfo, setClientInfo] = useState({
    OsDetails: '',
    BrowserVersion: '',
    ScreenResolution: '',
    CurrentWindowSize: '',
    BrowserLanguage: '',
    IsCookieEnabled: false,
    ConnectedAt: ''
  });

  useEffect(() => {
    const updateClientInfo = () => {
      setClientInfo({
        OsDetails: window.navigator.userAgent,
        BrowserVersion: window.navigator.appVersion,
        ScreenResolution: `${window.screen.width} * ${window.screen.height}`,
        CurrentWindowSize: `${window.innerWidth} * ${window.innerHeight}`,
        BrowserLanguage: window.navigator.language,
        IsCookieEnabled: window.navigator.cookieEnabled,
        ConnectedAt: new Date().toUTCString()
      });
    };

    updateClientInfo();
  }, []);

  return clientInfo;
};

export default useClientInfo;
